import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import RatesContent from '../components/RateComponents/RatesContent'

function Ratespage() {
  return (
    <>
        <Navbar/>
        <RatesContent/>
        <Footer/>
    </>
  )
}

export default Ratespage