import React, { useState } from 'react'
import DataTable from 'react-data-table-component'

function Table2() {
    const columns = [

        {
        name:'Company Locations',
        selector: row=>row.company,
        sortable: true,
        },

        {
            name:'Hotels',
            selector: row=>row.hotels,
            sortable: true,
        },

    ]

    const data = [
        {
            id:1,
            company: 'Microsoft',
            hotels: 'Westin'
        },
        {
            id:2,
            company: 'T-Mobile USA',
            hotels: 'Embassy Suites'
        },
        {
            id:3,
            company: 'Boeing',
            hotels: 'Hyatt Regency'
        },
        {
            id:4,
            company: 'Expedia',
            hotels: 'Hilton'
        },
        {
            id:5,
            company: 'Eddie Bauer',
            hotels: 'Courtyard'
        },
        {
            id:6,
            company: 'Bungie',
            hotels: 'Sheraton'
        },
        {
            id:7,
            company: 'Valve',
            hotels: "Silver Cloud"
        },
        {
            id:8,
            company: 'Concur',
            hotels: "Extended Stay America"
        },
        {
            id:9,
            company: 'Outerwall',
            hotels: 'Residence Inn'
        }
        
    ]
    const [records,setRecords] = useState(data)
    const handleFilter = (e) =>{
        const newData = data.filter(row=>{
            return row.company.toLowerCase().includes(e.target.value.toLowerCase())
        })
        setRecords(newData)
    }
  return (
    <div>
        <input type="text" placeholder='Filter records' className='p-2 m-2 bg-white border-2 border-black' onChange={handleFilter} />
        <DataTable
            columns={columns}
            data={records}
            pagination
            striped
            responsive
        />
    </div>
  )
}

export default Table2