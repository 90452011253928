import React from 'react'
import {Link} from 'react-router-dom';

function Footer() {
  return (
    <>
    <div className='flex items-center justify-center w-full px-8 py-12 bg-gray-900 md:px-12 md:py-16'>
        <div className='flex flex-col w-full space-y-4 md:flex-row md:space-x-4 md:w-10/12 '>
            {/* <div className='flex flex-col w-full md:w-4/12'>
                <h1 className='text-yellow-400'>CONNECT WITH US ON FACEBOOK</h1>
                <img src="" alt="" />
            </div> */}
            <div className='flex flex-col w-full space-y-4 md:w-6/12'>
                <h1 className='text-xl font-bold text-yellow-400' >SEATLE LIMOUSINE SERVICE</h1>
                <h1 className='text-sm text-yellow-400'><Link to='/service/seattle'>Seatle Limo Service</Link></h1>
                <h1 className='text-sm text-yellow-400'><Link to='/service/sedan'>Seattle Luxury Sedan Service</Link></h1>
                <h1 className='text-sm text-yellow-400'><Link to='/service/bellevue'>Bellevue Limo Service</Link></h1>
                <h1 className='text-sm text-yellow-400'><Link to='/service/bellevue-sedan'>Bellevue Luxury Sedan Car Service</Link></h1>
            </div>
            
            <div className='flex flex-col w-full space-y-4 md:w-6/12'>
                <h1 className='text-xl font-bold text-yellow-400' >24 Hour limo reservation</h1>
                <h1 className='text-lg text-yellow-400'>206-929-8800</h1>
                <h1 className='text-lg text-yellow-400'>(360)4SAHOTA</h1>
            </div>

            <div className='w-1/12'>

            </div>
        </div>
    </div>
    <div className='flex items-center justify-center w-full p-2 bg-black md:p-4 '>
        <div className='flex flex-col w-full md:w-10/12 md:flex-row lg:justify-between'>
            <div className='w-full md:w-3/12'>
            © COPYRIGHT MS LIMOUSINES, INC
            </div>
            <div className='hidden w-full md:w-5/12 '>
                <h1>LIMOS</h1>
                <h1>/</h1>
                <h1>POLICIES</h1>
                <h1>/</h1>
                <h1>PRIVACY POLICY</h1>
                <h1>/</h1>
                <h1>RESERVE NOW</h1>
                <h1>/</h1>
                <h1>CALL NOW</h1>
            </div>

        </div>

    </div>

    </>
    
  )
}

export default Footer