import React from "react";
import Getquote from "../Getquote";
import Limoform from "../Limoform";

function SchoolContent() {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full p-4 bg-yellow-500 sm:px-40">
        <h1 className="text-lg font-semibold text-white sm:text-2xl">
          School Dances and Prom Limo Service
        </h1>
      </div>
      <div className="flex flex-col items-center w-full pt-4 bg-white">
        <div className="flex flex-col items-center w-full sm:p-8 lg:w-10/12 ">
          <div className="flex flex-col w-full px-4 sm:px-16 sm:flex-row sm:space-x-4">
            <div className="flex flex-col items-center w-full space-y-4 sm:w-8/12 ">
              <p>
              MS Limousine offers limo service for school dances and proms
                around the Seattle area. Our limo fleet includes different types
                of stretch limos to meet your needs
              </p>
              <h1 className="text-3xl font-light text-center text-yellow-700">
                Seattle Prom Limo Service-Serving the Greater Seattle Area
              </h1>
              <p className="text-lg text-gray-700">
                Celebrate your special day with MS Limousine! Contact us 24/7
                to set up a reservation.
              </p>
              {/* <div className="flex items-center justify-center w-full space-x-2">
                <p>Share</p>
                <img className="w-4" src={facebook} alt="facebook icon" />
                <img className="w-4" src={twitter} alt="twitter icon" />
                <img className="w-4" src={google} alt="google icon" />
              </div> */}
            </div>
            <div className="w-0 md:w-1/12"></div>
            <Limoform />
          </div>
          <div className="w-full px-4 sm:px-8">
            <Getquote />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SchoolContent;
