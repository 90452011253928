import React from 'react'
import Footer from '../../../components/Footer'
import Navbar from '../../../components/Navbar'
import AirportContent from '../../../components/ServiceComponents/AirportContent'

function AirportPage() {
  return (
    <>
    <Navbar/>
    <AirportContent/>
    <Footer/>

    </>
  )
}

export default AirportPage