import React from 'react'
import Getquote from '../Getquote'
import img1 from '../../assets/reservepage1.png'
import Limoform2 from './Limoform2'

function Reservecontent() {
  return (
    <div className='flex flex-col items-center w-full bg-white'>
        <div className='w-full p-4 bg-yellow-500 sm:px-40'>
            <h1 className='text-lg font-semibold text-white md:text-2xl'>Reserve Now</h1>
        </div>
        <div className='flex flex-col w-full bg-white md:w-10/12'>
            <div className='flex flex-col w-full p-4 sm:space-x-12 sm:flex-row'>
                <div className='flex flex-col w-full space-y-4 sm:w-6/12'>
                    <h1 className='text-lg text-yellow-600 sm:text-2xl'>Online Reservations</h1>
                    <div className='self-start w-2/12 h-[.5vh] bg-gray-600'></div>
                    <h1>Please get in touch</h1>
                    <Limoform2/>
                </div>
                <div className='flex flex-col w-full space-y-4 sm:w-6/12 '>
                    <h1 className='text-lg text-yellow-600 sm:text-2xl'>24 hour Reservations</h1>
                    <div className='self-start w-2/12 h-[.5vh] bg-gray-600'></div>
                    <p>(206) 929-8800</p>
                    <p>(360)4SAHOTA</p>
                    <img src={img1} className='w-8/12 md:w-full' alt="" />
                    <p>One of MS Limo’s luxurious Lincoln stretch limos</p>
                </div>

            </div>
            <div className='px-3 sm:px-6'>
                <Getquote/>
            </div>
        </div>

    </div>
  )
}

export default Reservecontent