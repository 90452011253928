import React from 'react'
import Getquote from '../Getquote'
import img1 from '../../assets/bellevue.jpg'

function BellevueContent() {
  return (
    <div className='flex flex-col items-center w-full bg-white'>
        <div className='w-full p-4 px-12 bg-yellow-500 sm:px-40'>
            <h1 className='text-lg font-semibold text-white sm:text-2xl'>Bellevue Limo Service</h1>
        </div>
        <div className='flex flex-col w-10/12 py-4 space-y-4 sm:pt-16 '>
            <img src={img1} alt = ""/>
            <h1 className='w-full text-lg text-yellow-500 sm:text-2xl'>
            Bellevue Limo Service
            </h1>
            <p>Whether you need transportation for a night on the town or a painless Town Car service to Sea-Tac for your next business trip, MS Limousine is your best choice for limo service to and from Bellevue and other cities on the Eastside.

                Check out our fleet and our Bellevue limo service.

            </p>
            {/* <div className='flex items-center justify-center w-full space-x-2'>
                <p>Share</p>
                <img className='w-4' src={facebook} alt="facebook icon"/>
                <img className='w-4' src={twitter} alt="twitter icon"/>
                <img className='w-4' src={google} alt="google icon"/>
            </div> */}
            <Getquote/>
            
        </div>
    </div>
  )
}

export default BellevueContent