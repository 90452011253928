import React from "react";
import img1 from "../../assets/seattle-traffic.jpg";
import img2 from "../../assets/crown-limo-seattle2.jpg";
import car from "../../assets/herocar.png";
import { Link } from "react-router-dom";

function Carousel() {
  return (
    <div className="w-full overflow-hidden carousel">
      <div id="slide1" className="relative w-full carousel-item carousel-1">
        <div className="absolute flex justify-between transform -translate-y-1/2 left-1 sm:left-5 right-1 sm:right-5 top-1/2">
          <a href="#slide2" className="btn btn-circle">
            ❮
          </a>
          <a href="#slide2" className="btn btn-circle">
            ❯
          </a>
        </div>
        <div
          className="w-full  h-[90vh] bg-cover flex items-center justify-center p-4 sm:p-20 sm:px-20"
          style={{ backgroundImage: `url(${img2})` }}
        >
          <div className="flex flex-col items-center w-full px-4 sm:py-2 sm:flex-row sm:px-12 md:px-16 lg:px-24 ">
            <div className="flex flex-col items-center order-2 w-10/12 space-y-4 sm:w-5/12 sm:order-1">
              <h1 className="self-start text-left text-white text-md">
                {" "}
                FROM MS LIMOUSINE{" "}
              </h1>
              <h1 className="self-start text-2xl font-bold text-left text-white md:text-3xl lg:text-4xl ">
                {" "}
                Seatle limo service{" "}
              </h1>
              <p className="self-start text-left text-white text-md ">
                Whether you're looking to hire the best limo service for your
                wedding, a night on the town, or for any other event, MS
                Limousine offers many different types of limousines to fit your
                transportation needs in the Seattle area. Request a FREE quote
                today and we will get back to you ASAP.
              </p>
              <div className="flex items-center justify-start w-full space-x-4 ">
                <button className="h-10 p-2 text-xs font-bold text-white bg-yellow-400 rounded-md lg:h-12 lg:text-sm w-30 ">
                  <Link to="/reserve">REQUEST A QUOTE</Link>
                </button>
                <button className="flex items-center justify-center h-10 p-2 text-xs font-bold text-white border-4 border-white rounded-md lg:h-12 lg:text-sm w-30">
                  <a href="tel:206-929-8800">CONTACT US</a>
                </button>
              </div>
            </div>
            <div className="hidden w-1/12 lg:block"></div>
            <div className="order-1 w-10/12 sm:w-6/12 sm:order-2">
              <img src={car} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div id="slide2" className="relative w-full carousel-item carousel-2">
        <div className="absolute flex justify-between transform -translate-y-1/2 right-3 left-3 sm:left-5 sm:right-5 top-1/2">
          <a href="#slide1" className="btn btn-circle">
            ❮
          </a>
          <a href="#slide1" className="btn btn-circle">
            ❯
          </a>
        </div>
        <div
          className=" w-full h-[90vh] bg-cover flex items-center justify-center "
          style={{ backgroundImage: `url(${img1})` }}
        >
          <div className="flex flex-col items-center justify-center w-8/12 space-y-4 md:w-6/12">
            <h1 className="text-white text-md">
              LET US WORRY AT SEATLE TRAFIC
            </h1>
            <h1 className="flex items-center justify-center w-full p-2 px-6 text-2xl font-bold text-white bg-black sm:p-4 sm:px-10 sm:text-4xl">
              Curbside Service to Sea-Tac Airport
            </h1>
            <p className="text-center text-white text-md">
              Let us deal with Seattle traffic and the logistics of getting you
              to the airport on time; it's what we do! MS Limousine is licensed
              by the Port of Seattle to drop off at Sea-Tac Airport.
            </p>

            <div className="flex items-center justify-center w-full space-x-4 ">
              <Link to="/reserve">
                <button className="h-12 p-2 text-xs font-bold text-white bg-yellow-400 rounded-md md:h-10 md:text-sm w-30 ">
                  REQUEST A QUOTE
                </button>
              </Link>
              <button className="flex items-center justify-center h-12 p-2 text-xs font-bold text-white border-4 border-white rounded-md md:h-10 md:text-sm w-30">
                <a href="tel:206-929-8800">CONTACT US</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Carousel;
