import React from 'react'
import Footer from '../../../components/Footer'
import Navbar from '../../../components/Navbar'
import SedanContent from '../../../components/ServiceComponents/SedanContent'

function SedanPage() {
  return (
    <>
    <Navbar/>
    <SedanContent/>
    <Footer/>
    </>
  )
}

export default SedanPage