import React, { useState } from 'react'
import DataTable from 'react-data-table-component'

function Table1() {
    const columns = [

        {
        name:'CITY',
        selector: row=>row.city,
        sortable: true,
        },

        {
            name:'SEDAN',
            selector: row=>row.sedan,
            sortable: true,
        },
        {
            name:'SUV',
            selector: row=> row.suv,
            sortable: true,
        }

    ]

    const data = [
        {
            id:1,
            city: 'Ballard',
            sedan: '$80+',
            suv: '$140+'
        },
        {
            id:2,
            city: 'Bellevue',
            sedan: '$70+',
            suv: '$130+'
        },
        {
            id:3,
            city: 'Bothell',
            sedan: '$90+',
            suv: '$150+'
        },
        {
            id:4,
            city: 'Duvall',
            sedan: '$135+',
            suv: '$190+'
        },
        {
            id:5,
            city: 'Edmonds',
            sedan: '$95+',
            suv: '$145+'
        },
        {
            id:6,
            city: 'Everett',
            sedan: '$120+',
            suv: '$160+'
        },
        {
            id:7,
            city: 'Fall City',
            sedan: '$90+',
            suv: '$140+'
        },
        {
            id:8,
            city: 'Federal Way',
            sedan: '$70+',
            suv: '$130+'
        },
        {
            id:9,
            city: 'Issaquah',
            sedan: '$80+',
            suv: '$135+'
        },
        {
            id:10,
            city: 'Juania',
            sedan: '$90+',
            suv: '$145+'
        },
        {
            id:11,
            city: 'Mapple Valley',
            sedan: '$80+',
            suv: '$140+'
        },
        {
            id:12,
            city: 'Mercer Island',
            sedan: '$70+',
            suv: '$130+'
        },
        {
            id:13,
            city: 'Mill Creek',
            sedan: '$95+',
            suv: '$145+'
        },
        {
            id:14,
            city: 'Monroe',
            sedan: '$155+',
            suv: '$200+'
        },
        {
            id:15,
            city: 'Mukilteo',
            sedan: '$135+',
            suv: '$185+'
        },
        {
            id:16,
            city: 'North Bend',
            sedan: '$140+',
            suv: '$185+'
        },
        {
            id:17,
            city: 'Redmond',
            sedan: '$80+',
            suv: '$135+'
        },
        {
            id:18,
            city: 'Renton',
            sedan: '$70+',
            suv: '$130+'
        },
        {
            id:19,
            city: 'Sammamish',
            sedan: '$85+',
            suv: '$140+'
        },
        {
            id:20,
            city: 'Seattle',
            sedan: '$70+',
            suv: '$130+'
        },
        {
            id:21,
            city: 'Kenmore',
            sedan: '$100+',
            suv: '$150+'
        },
        {
            id:22,
            city: 'Kent',
            sedan: '$70+',
            suv: '$130+'
        },
        {
            id:23,
            city: 'Kirkland',
            sedan: '$75+',
            suv: '$135+'
        },
        {
            id:24,
            city: 'Lynnwood',
            sedan: '$95+',
            suv: '$150+'
        },
        {
            id:25,
            city: 'Snohomish',
            sedan: '$150+',
            suv: '$200+'
        },
        {
            id:26,
            city: 'Snoqualmie',
            sedan: '$135+',
            suv: '$180+'
        },
        {
            id:27,
            city: 'Tacoma',
            sedan: '$95+',
            suv: '$150+'
        },
        {
            id:28,
            city: 'Woodinville',
            sedan: '$90+',
            suv: '$145+'
        },
        
    ]
    const [records,setRecords] = useState(data)
    const handleFilter = (e) =>{
        const newData = data.filter(row=>{
            return row.city.toLowerCase().includes(e.target.value.toLowerCase())
        })
        setRecords(newData)
    }
  return (
    <div>
        <input type="text" placeholder='filter records' className='p-2 m-2 bg-white border-2 border-black' onChange={handleFilter} />
        <DataTable
            columns={columns}
            data={records}
            pagination
            striped
            responsive
        />
    </div>
  )
}

export default Table1