import React from 'react'
import Footer from '../../../components/Footer'
import Navbar from '../../../components/Navbar'
import BellevueContent from '../../../components/ServiceComponents/BellevueContent'

function BellevuePage() {
  return (
    <>
    <Navbar/>
    <BellevueContent/>
    <Footer/>
    </>

  )
}

export default BellevuePage