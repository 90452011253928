import React from "react";
import Getquote from "../Getquote";
import Limoform from "../Limoform";
import Table2 from "./Table2";

const content = [
  {
    heading: "Bellevue Luxury Sedan Car Service",
    content:
      "MS Limo has your Luxury Sedan service needs in the Bellevue and surrounding cities covered.",
  },

  {
    heading: "Bellevue to Sea-Tac Airport Luxury Sedan Service",
    content:
      "Contact us to learn more about our Luxury Sedan service to Sea-Tac International Airport. Our professional drivers can take you to your destination, whether that be your home, hotel, or any other place you need to get to.Visit our Limo Rates page to learn more about rates to specific cities, including Seattle, Bellevue, Redmond, Kirkland, and many others for limo service to and from Sea-Tac International Airport.",
  },

  {
    heading: "Most Popular Locations in Bellevue",
    content:
      "MS Limo provides Bellevue Luxury Sedan service to all parts of Bellevue. The locations below are the most common for our clients.",
  },
];

function BellevueSedanContent() {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full p-4 bg-yellow-500 sm:px-40">
        <h1 className="text-lg font-semibold text-white sm:text-2xl">
          Bellevue Luxury Sedan Car Service
        </h1>
      </div>
      <div className="flex flex-col items-center w-full pt-4 bg-white">
        <div className="flex flex-col items-center e-full lg:w-10/12 md:p-8 ">
          <div className="flex flex-col w-full px-4 sm:px-16 sm:flex-row sm:space-x-4">
            <div className="flex flex-col items-center w-full space-y-4 sm:w-8/12 ">
              {content.map((c) => {
                return (
                  <div className="w-full px-2">
                    <h1 className="text-lg text-yellow-500 sm:text-2xl">{c.heading}</h1>
                    <p>{c.content}</p>
                  </div>
                );
              })}
              <div className="w-full">
                <Table2 />
              </div>
              {/* <div className="flex items-center justify-center w-full space-x-2">
                <p>Share</p>
                <img className="w-4" src={facebook} alt="facebook icon" />
                <img className="w-4" src={twitter} alt="twitter icon" />
                <img className="w-4" src={google} alt="google icon" />
              </div> */}
            </div>
            <div className="w-0 md:w-1/12"></div>
            <Limoform />
          </div>
          <div className="w-full px-4 sm:px-16">
            <Getquote />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BellevueSedanContent;
