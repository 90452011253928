import './App.css';
import {
  Route,
  Routes
} from "react-router-dom";
import Homepage from './pages/Homepage';
import LimosPage from './pages/LimosPage';
import Ratespage from './pages/Ratespage';
import Reservenow from './pages/Reservenow';
import SeattlePage from './pages/Service/Limo Service/SeattlePage';
import BellevuePage from './pages/Service/Limo Service/BellevuePage';
import CorporatePage from './pages/Service/Limo Service/CorporatePage';
import SchoolPage from './pages/Service/Limo Service/SchoolPage';
import SedanPage from './pages/Service/Luxury Sedan Service/SedanPage';
import BellevueSedanPage from './pages/Service/Luxury Sedan Service/BellevueSedanPage';
import AirportPage from './pages/Service/Airport Limo Service/AirportPage';

function App() {
  return (
    <div className="w-full">
      <Routes>
      <Route path='/service/airport' element={<AirportPage/>}>
        </Route>
      <Route path='/service/bellevue-sedan' element={<BellevueSedanPage/>}>
        </Route>
      <Route path='/service/sedan' element={<SedanPage/>}>
        </Route>
      <Route path='/service/prom' element={<SchoolPage/>}>
        </Route>
      <Route path='/service/corporate' element={<CorporatePage/>}>
        </Route>
      <Route path='/service/bellevue' element={<BellevuePage/>}>
        </Route>
      <Route path='/service/seattle' element={<SeattlePage/>}>
        </Route>
      <Route path='/reserve' element={<Reservenow/>}>
        </Route>
      <Route path='/rates' element={<Ratespage/>}>
        </Route>
        <Route path='/limos' element={<LimosPage/>}>
        </Route>
        <Route path='/' element={<Homepage/>}>
        </Route>
      </Routes>
    </div> 
  );
}

export default App;
