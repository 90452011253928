import { useState } from "react";
import { Link } from "react-router-dom";
import Drop from "./Drop";
import logo from "../assets/Logo.png";

export default function Navbar() {
  const [navbar, setNavbar] = useState(false);

  return (
    <nav className="w-full bg-black shadow">
      <div className="justify-between px-4 py-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
        <div className="w-3/12"></div>
        <div className="flex items-center justify-between py-3 md:py-5 md:block">
          <Link to="/">
            <img src={logo} className="w-48 h-24" alt="" />
          </Link>
          <div className="md:hidden">
            <button
              className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
              onClick={() => setNavbar(!navbar)}
            >
              {navbar ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>

        <div>
          <div
            className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
              navbar ? "block" : "hidden"
            }`}
          >
            <ul className="relative items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
              <li className="text-gray-300 hover:text-yellow-300">
                <Drop />
                <div className="absolute hidden border-2 border-black top-4">
                  <ul className="flex flex-col items-center ">
                    <li>Limo service</li>
                    <li>Limo sedan service</li>
                    <li>Limo service 3</li>
                  </ul>
                </div>
              </li>
              <li className="text-gray-300 hover:text-yellow-300">
                <Link to="/limos">LIMOS</Link>
              </li>
              <li className="text-gray-300 hover:text-yellow-300">
                <Link to="/rates">RATES</Link>
              </li>
              <li className="text-gray-300 hover:text-yellow-300">
                <Link to="/reserve">RESERVE NOW</Link>
              </li>
              <li className="text-gray-300 hover:text-yellow-300">
                <a href="tel:206-929-8800">CALL NOW</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
