import React from 'react'
import Footer from '../../../components/Footer'
import Navbar from '../../../components/Navbar'
import CorporateContent from '../../../components/ServiceComponents/CorporateContent'

function CorporatePage() {
  return (
    <>
    <Navbar/>
    <CorporateContent/>
    <Footer/>
    </>
  )
}

export default CorporatePage