import React from 'react'
import Footer from '../components/Footer'
import Limos from '../components/LimosComponents/Limos'
import Navbar from '../components/Navbar'

function LimosPage() {
  return (
    <>
        <Navbar/>
        <Limos/>
        <Footer/>
    </>
  )
}

export default LimosPage