import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser';

function Limoform() {

    const form = useRef();
    const [name,setName] = useState('')
    const [email,setEmail] = useState('')
    const [phone,setPhone]= useState('')
    const [msg,setMsg] = useState('')

    const sendEmail = (e) => {
      e.preventDefault();
      if(name===''||email===''||phone===''||msg===''){
        window.alert('Please add all inputs, cannot send empty email')
        return
      }

  
      emailjs.sendForm('service_z5ij3ir', 'template_mc1ukfv', form.current, 'gloVX8v3yZKtPCvnu')
        .then((result) => {
            window.alert('sent')
        }, (error) => {
            console.log(error.text);
        }).then(setName('')).then(setEmail('')).then(setPhone('')).then(setMsg(''))


        
    };

  return (
    <div className='flex flex-col w-10/12 space-y-4 sm:w-3/12'>
                        <h1 className='text-2xl font-semibold text-yellow-600 sm:text-4xl'>Reserve a Limo </h1>
                        <div className='self-start w-2/12 h-[.5vh] bg-gray-600'></div>
                        <p className='text-sm text-gray-700'>Please fill out the form below or call us for a quick quote on our limo services. We will get back to you as soon as possible!</p>
                        <div className='w-full py-4 space-y-4'>
                                <form ref={form} onSubmit={sendEmail}>
                                <div className='flex justify-between w-full pr-2 space-y-2 sm:block sm:w-6/12'>
                                    <label className='w-full'>Name * </label>
                                    <input name='name' value={name} onChange={e=>{setName(e.target.value)}}  className='w-[150px] h-8 p-2 bg-white border-2 border-black  rounded-xs'></input>
                                </div>
                                <div className='flex justify-between w-full pr-2 space-y-2 sm:block sm:w-6/12'>
                                    <label>Email * </label>
                                    <input name='emailid' value={email} onChange={e=>{setEmail(e.target.value)}}  className=' w-[150px] h-8 p-2 bg-white border-2 border-black rounded-xs'></input>
                                </div>
                                <div className='flex justify-between w-full pr-2 space-y-2 sm:block sm:w-6/12'>
                                    <label>Phone * </label>
                                    <input name='phonenum' value={phone} onChange={e=>{setPhone(e.target.value)}} className=' w-[150px]  h-8 p-2 bg-white border-2 border-black rounded-xs'></input>
                                </div>
                                <div className='flex justify-between w-full pr-2 space-y-2 sm:block '>
                                    <label >Message * </label>
                                    <input name='message' value={msg} onChange={e=>{setMsg(e.target.value)}}  className='p-2 w-[150px]  bg-white border-2 border-black md:w-full h-36 rounded-xs'></input>
                                </div>
                            <p className='mt-4 text-xs text-gray-600'>Please include the time, number of passengers, and pick-up/drop-off locations, as well as any other important details.</p>
                            <button type='submit' className='w-6/12 p-1 px-4 mt-4 text-white bg-yellow-600 rounded-lg sm:p-2 lg:w-4/12'> Submit </button>
                            </form>
                        </div>
                        <div className='flex flex-col items-start w-full mt-4 space-y-4 sm:items-center'>
                            <h1 className='w-full text-2xl font-bold text-yellow-600'>24/7 Reservations!</h1>
                            <div className='self-start w-2/12 h-[.5vh] bg-gray-600'></div>
                            <p className='text-xl font-light text-yellow-600 md:text-3xl'>206 929-8800</p>
                            <p className='text-xl font-light text-yellow-600 md:text-3xl'>(360)4SAHOTA</p>
                        </div>
                    </div>
  )
}

export default Limoform