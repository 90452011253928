import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Reservecontent from '../components/ReserveComponents/Reservecontent'

function Reservenow() {
  return (
    <>
    <Navbar/>
    <Reservecontent/>
    <Footer/>
    </>
  )
}

export default Reservenow