import React from 'react'
import { Link } from 'react-router-dom';
import './Drop.css' ;

function Drop() {
  return (
    <div class="group inline-block">
      <button class="outline-none focus:outline-none md:px-3 py-1 bg-black flex items-center min-w-32 sm:-translate-x-0 -translate-x-5">
        <span class="pr-1 font-semibold flex-1">SERVICES</span>
        <span>
          <svg
            class="fill-current h-4 w-4 transform group-hover:-rotate-180
        transition duration-250 ease-in-out"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </span>
      </button>
      <ul
        class="bg-white border rounded-sm transform scale-0 group-hover:scale-100 absolute 
  transition duration-300 ease-in-out origin-top min-w-32 z-10 md:w-60 w-40"
      >
        {/* 1st dropdown */}
        <li class="rounded-sm px-3 py-1 hover:bg-gray-100 text-gray-500">
          <button class="w-full text-left flex items-center outline-none focus:outline-none">
            <span class="pr-1 flex-1">LIMO SERVICE</span>
            <span class="mr-auto">
              <svg
                class="fill-current h-4 w-4
            transition duration-150 ease-in-out"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </span>
          </button>
          <ul
            class="bg-white border z-10 rounded-sm absolute top-0 right-0 
  transition duration-300 ease-in-out origin-top-left
  min-w-32
  "
          >
            <Link to='/service/seattle'>
            <li class="px-3 py-1 hover:bg-gray-100 text-gray-500">SEATTLE LIMO SERVICE</li>
            </Link>
            <Link to='/service/bellevue'>
            <li class="px-3 py-1 hover:bg-gray-100 text-gray-500">BELLEVUE LIMO SERVICE</li>
            </Link>
            <Link to='/service/corporate'>
            <li class="px-3 py-1 hover:bg-gray-100 text-gray-500">LIMO CORPORATE ACCOUT</li>
            </Link>
            <Link to='/service/prom'>
            <li class="px-3 py-1 hover:bg-gray-100 text-gray-500">
              SCHOOL DANCES AND PROM LIMO SERVICE
            </li>
            </Link>
          </ul>
        </li>

        {/* 2nd dropdown */}
        <li class="rounded-sm px-3 py-1 hover:bg-gray-100 text-gray-500">
          <button class="w-full text-left flex items-center outline-none focus:outline-none">
            <span class="pr-1 flex-1">LUXURY SEDAN SERVICES</span>
            <span class="mr-auto">
              <svg
                class="fill-current h-4 w-4
                transition duration-150 ease-in-out"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </span>
          </button>
          <ul
            class="bg-white border z-10 rounded-sm absolute top-0 right-0 
            transition duration-300 ease-in-out origin-top-left
            min-w-32
            "
            >
              <Link to='/service/sedan'>
            <li class="px-3 py-1 hover:bg-gray-100 text-gray-500">
              SEDAN LUXURY SEDAN SERVICE
            </li>
              </Link>
              <Link to='/service/bellevue-sedan'>
            <li class="px-3 py-1 hover:bg-gray-100 text-gray-500">
              BELLEVUE LUXURY SEDAN CAR SERVICE
            </li>
              </Link>
          </ul>
        </li>

        {/* 3rd dropdown */}
        <li class="rounded-sm relative px-3 py-1 hover:bg-gray-100 text-gray-500">
          <button class="w-full text-left flex items-center outline-none focus:outline-none">
            <span class="pr-1 flex-1">SEATTLE AIRPORT LIMO SERVICE</span>
            <span class="mr-auto">
              <svg
                class="fill-current h-4 w-4
            transition duration-150 ease-in-out"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </span>
          </button>
          <ul
            class="bg-white border z-10 rounded-sm absolute top-0 right-0 
  transition duration-300 ease-in-out origin-top-left
  min-w-32
  "
          >
            <Link to='/service/airport'>
            
            <li class="px-3 py-1 hover:bg-gray-100 text-gray-500">
              SEA-TAC AIRPORT INFORMATION
            </li>
            </Link>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default Drop