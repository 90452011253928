import React from "react";

import Getquote from "../Getquote";
import Limoform from "../Limoform";
import Table1 from "./Table1";
const content = [
  {
    text: "** If your location is not listed, please call, text, or email for rates and any additional information.",
  },
  {
    text: "** Gratuity, Fuel Surcharge, Tolls, and Airport Fees are not included.",
  },
  {
    text: "** Other fees may apply: STC 8%, International arrivals, etc.",
  },
  {
    text: "** After hours fee: $15 + for trips between Midnight and 5:30am (Time frame is subject to change depending on location).",
  },
  {
    text: "** Meet & Greet Baggage claim: $15 +",
  },
  {
    text: "** Industry standard 20% gratuity is added to all hourly rentals**",
  },
];

function RatesContent() {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full p-4 px-20 bg-yellow-500 md:px-40">
        <h1 className="text-xl font-semibold text-white sm:text-2xl">
          Hourly Rental Rates
        </h1>
      </div>
      <div className="flex flex-col items-center w-full bg-white">
        <div className="flex flex-col items-center w-full p-4 sm:p-8 lg:w-10/12 ">
          <div className="flex flex-col w-full px-2 space-x-4 xs:px-8 sm:px-16 sm:flex-row">
            <div className="flex flex-col items-center w-full space-y-4 sm:w-8/12 ">
              <div>
                <p className="text-xl text-yellow-600">Hourly Rental Rates</p>
              </div>
              <div>
                <p>RATES TO/FROM SEATAC INTERNATIONAL AIRPORT</p>
                <Table1 />
              </div>
              <div className="flex flex-col space-y-6">
                {content.map((c) => {
                  return <h1 className="text-lg text-yellow-500 sm:text-xl md:text-2xl">{c.text}</h1>;
                })}
              </div>
              <div className="flex flex-col items-center">
                <div>sahota4647@gmail.com</div>
                <div>
                  206-929-8800 | (360)4SAHOTA
                </div>
              </div>
              {/* <div className='flex items-center justify-center w-full space-x-2'>
                <p>Share</p>
                <img className='w-4' src={facebook} alt="facebook icon"/>
                <img className='w-4' src={twitter} alt="twitter icon"/>
                <img className='w-4' src={google} alt="google icon"/>
            </div> */}
            </div>
            <div className="w-0 md:w-1/12"></div>
            <Limoform />
          </div>
          <div className="w-full px-4 sm:px-16">
            <Getquote />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RatesContent;
