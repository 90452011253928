import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

function Limoform2() {
  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [msg, setMsg] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    if (name === "" || email === "" || phone === "" || msg === "") {
      window.alert("Please add all inputs, cannot send empty email");
      return;
    }

    emailjs
      .sendForm(
        "service_z5ij3ir",
        "template_mc1ukfv",
        form.current,
        "gloVX8v3yZKtPCvnu"
      )
      .then(
        (result) => {
          window.alert("Sent");
        },
        (error) => {
          console.log(error.text);
        }
      )
      .then(setName(""))
      .then(setEmail(""))
      .then(setPhone(""))
      .then(setMsg(""));
  };

  return (
    <div className="flex flex-col w-10/12 py-4 space-y-4">
      <form ref={form} className="space-y-4" onSubmit={sendEmail}>
        <div className="flex justify-between w-full space-y-2">
          <div className="w-6/12"><label className="w-2/10">Name * </label></div>
          <input
            name="name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            className="w-10/12 h-8 p-2 bg-white border-2 border-black rounded-xs"
          ></input>
        </div>
        <div className="flex justify-between w-full space-y-2">
          <div className="w-6/12"><label>Email * </label> </div>
          <input
            name="emailid"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            className="w-10/12 h-8 p-2 bg-white border-2 border-black rounded-xs"
          ></input>
        </div>
        <div className="flex justify-between w-fullspace-y-2">
          <div className="w-6/12"><label>Phone * </label></div>
          <input
            name="phonenum"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
            className="w-10/12 h-8 p-2 bg-white border-2 border-black rounded-xs"
          ></input>
        </div>
        <div className="flex justify-between w-full space-y-2">
          <div className="w-6/12"><label>Message * </label></div>
          <textarea
            name="message"
            value={msg}
            onChange={(e) => {
              setMsg(e.target.value);
            }}
            className="w-10/12 p-2 bg-white border-2 border-black h-36 rounded-xs"
          ></textarea>
        </div>
        <p className="mt-4 text-xs text-gray-600">
          Please include the time, number of passengers, and pick-up/drop-off
          locations, as well as any other important details.
        </p>
        <button
          type="submit"
          className="w-6/12 p-1 px-4 mt-4 text-white bg-yellow-600 rounded-lg sm:p-2 lg:w-4/12"
        >
          {" "}
          Submit{" "}
        </button>
      </form>
    </div>
  );
}

export default Limoform2;
