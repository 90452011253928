import React from "react";
import img1 from "../../assets/limos1.jpg";
import img2 from "../../assets/limos2.jpg";
import img3 from "../../assets/limos3.jpg";
import img4 from "../../assets/limos4.jpg";
import img5 from "../../assets/limos5.jpg";
import img6 from "../../assets/limos6.jpg";
import Getquote from "../Getquote";
import Limoform from "../Limoform";

function Limos() {
  const content = [
    {
      index: 1,
      heading: "Lincoln Continental – 3 Passengers",
      img: img2,
      text: "Our latest luxury sedan addition! It offers beautiful and spacious interior, leather seats, comfort at its best and the convenience of a phone charger.",
    },
    {
      index: 2,
      heading: "Lincoln Town Car – 4 Passengers",
      img: img3,
      text: "The Lincoln Town Car sedans offer luxury and comfort, perfect for your corporate needs or any occasion",
    },
    {
      index: 3,
      heading: "Lincoln Stretch Limousine – 10 Passengers",
      img: img4,
      text: "Leather seats, AC, AM/FM/DVD/CD, Ice Chest Storage, TV Mirrors, Dimmer control mood lights, privacy divider, mood lighting, Floor & bar lights. Accommodates up to 10 passengers.",
    },
    {
      index: 4,
      heading: "Chrysler 300 Stretch Limousine – 10 Passengers",
      img: img5,
      text: "New design and Luxury & comfort Class.  Accommodate up to 10 Adults with leather seats,  AM/FM Radio, DVD  w/CD, Ice Chest Storage, TV & VCR, Vanity Mirrors, Dimmer controlled mood lights, Privacy divider,  Mood lights, Floor and Bar lights",
    },
    {
      index: 5,
      heading: "GMC Yukon Denali SUV – 6 Passengers",
      img: img6,
      text: "Leather seats and a comfy ride to any occasion. Our GMC SUV is perfect if you need to bring extra luggage on a trip.",
    },
  ];
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full p-4 bg-yellow-500 sm:px-40">
        <h1 className="text-lg font-semibold text-white sm:text-2xl">Limos</h1>
      </div>
      <div className="flex flex-col items-center w-full bg-white">
        <div className="flex flex-col items-center w-full p-4 sm:p-8 lg:w-10/12 ">
          <div className="flex flex-col w-full px-4 space-x-4 sm:flex-row md:px-16">
            <div className="flex flex-col items-center space-y-4 md:w-10/12 lg:w-8/12 ">
              <img src={img1} alt="" />
              <h1 className="text-3xl font-light text-center text-yellow-700">
              MS Limousine Fleet
              </h1>
              <p className="text-gray-700 text-md sm:text-lg">
                Our limo fleet consists of Luxury Sedans, Stretch Limos, and
                SUVs to meet your transportation needs to weddings, proms, and
                sporting events in the Seattle area.
              </p>
              {content.map((c) => {
                return c.index % 2 === 0 ? (
                  <>
                    <h1 className="self-start text-xl font-light text-yellow-700 sm:text-2xl">
                      {c.heading}
                    </h1>
                    <div className="flex justify-center p-2 space-x-2 ">
                      <img src={c.img} className='w-6/12' alt="" />
                      <p className="text-xs text-gray-500 md:text-lg"> {c.text}</p>
                    </div>
                  </>
                ) : (
                  <>
                    <h1 className="self-start text-xl font-light text-yellow-700 sm:text-2xl">
                      {c.heading}
                    </h1>
                    <div className="flex justify-center p-2 space-x-2 ">
                      <img className="order-2 w-6/12" src={c.img} alt="" />
                      <p className="order-1 text-xs text-gray-500 md:text-lg"> {c.text}</p>
                    </div>
                  </>
                );
              })}
              {/* <div className="flex items-center justify-center w-full space-x-2">
                <p>Share</p>
                <img className="w-4" src={facebook} alt="facebook icon" />
                <img className="w-4" src={twitter} alt="twitter icon" />
                <img className="w-4" src={google} alt="google icon" />
              </div> */}
            </div>
            <div className="w-0 sm:w-1/12"></div>
            <Limoform />
          </div>
          <div className="w-full px-8 md:px-16">
            <Getquote />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Limos;
