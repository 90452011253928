import React from "react";
import { Link } from "react-router-dom";
const content4 =
  "Get a quick quote through our online reservation system or give us a call";
function Getquote() {
  return (
    <div className="flex flex-col w-full py-4 sm:flex-row">
      <div className="flex flex-col w-10/12">
        <h1 className="py-2 text-xl font-light text-yellow-500 sm:text-3xl">
          Reserve a Limo Today!{" "}
        </h1>
        <p>{content4}</p>
      </div>
      
      <div className="flex items-center justify-center w-6/12 p-2 sm:w-4/12 md:w-2/12">
        <button className="w-full h-10 text-xs font-bold text-white bg-yellow-600 rounded-md md:h-10 md:text-sm">
        <Link to="/reserve">GET A QUOTE!</Link>
        </button>
      </div>
    </div>
  );
}

export default Getquote;
