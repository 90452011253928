import React from "react";
import Getquote from "../Getquote";
import Limoform from "../Limoform";
import img1 from "../../assets/seattle-limo-service1.jpg";

const content = [
  { name: "wedding" },
  {
    name: "night out on the town in Seattle/any city in the Puget Sound area proms",
  },
  { name: "Seattle airport service" },
  { name: "Seattle cruise service" },
  { name: "bachelor/bachelorette parties" },
  {
    name: "casino day trips to casinos like Snoqualmie Casino,, Tulalip Resort Casino, and Muckleshoot Casino",
  },
  { name: "and more!" },
];

const content2 = [
  {
    heading: "MS Limousine- Seattle Limo Service Since 1998!",
    content:
      " Our limo service is committed to being the best and most reliable limo service for you in Seattle and the surrounding areas. There is a reason why thousands of our clients would recommend our limo rentals. We at MS Limo have been serving our clients with luxury transportation since our company was founded in 1998. Our wide array of limos like Town Cars, stretch limos, and SUVs ensure that we meet all of your transportation needs.",
  },

  {
    heading: "Our Limousine Fleet",
    content:
      "Our limos for reservations include Chryslers and Lincoln stretch limos. To learn more about our fleet, check out our Fleet page.",
  },

  {
    heading: "The MS Limousine Difference",
    content:
      "All of our limousines are maintained to high standards. This, coupled with our professional limo drivers, insures a smooth trip for your transportation needs to business trips, family outings, sports games, and events like weddings and proms.",
  },

  {
    heading: "Professional and Knowledgeable Limo Drivers",
    content:
      "We know that you value an experienced and knowledgeable Seattle limo driver. Our limo professionals have experience and they know the Seattle area well.",
  },
  {
    heading: "Easy Seattle Limo Service Reservations",
    content:
      "Fill out the form on the side of the site to get in touch with us. Please let us know the dates, times, and other important information about the limo rental you’re requesting. We will get back to you with a quote within a couple of hours!",
  },
  {
    heading: "MS Limousine’s Mission",
    content:
      "MS Limousine aims to be the top choice for all your transportation needs in the Seattle area, including limo rentals (limo packages and hourly limo rental), town car transfers, weddings, business meetings, concerts, proms, birthdays, and other special events. We are your one-stop-shop for Seattle limo services of any kind 24 hours a day, 7 days a week. MS Limo would like to thank you for considering our local limo company in advance for giving us the opportunity to serve you.",
  },
];

function SeatleContent() {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full p-4 bg-yellow-500 sm:px-40">
        <h1 className="text-lg font-semibold text-white sm:text-2xl">
          Seattle Limo Service
        </h1>
      </div>
      <div className="flex flex-col items-center w-full py-4 bg-white">
        <div className="flex flex-col items-center w-full sm:p-8 lg:w-10/12 ">
          <div className="flex flex-col w-full space-x-4 sm:flex-row md::px-16">
            <div className="flex flex-col items-center w-full px-4 space-y-4 sm:px-8 sm:w-8/12 ">
              <img src={img1} alt="" />
              <div>
                <h1 className="w-full text-2xl text-yellow-500">
                  Seattle Limo Service for All Kinds of Events
                </h1>
                <p>
                  We regularly provide limo service for weddings and other
                  special events. Our limos are perfect for all of the following
                  events:
                </p>
                <ul className="px-4 list-disc ">
                  {content.map((c) => {
                    return <li className="">{c.name}</li>;
                  })}
                </ul>
                <p>
                  Get in touch with us to see which limo will fit your needs for
                  your special event.
                </p>
              </div>
              <div className="w-full space-y-2">
                {content2.map((c) => {
                  return (
                    <>
                      <h1 className="w-full text-2xl text-yellow-500">
                        {c.heading}{" "}
                      </h1>
                      <p>{c.content}</p>
                    </>
                  );
                })}
              </div>
              {/* <div className="flex items-center justify-center w-full space-x-2">
                <p>Share</p>
                <img className="w-4" src={facebook} alt="facebook icon" />
                <img className="w-4" src={twitter} alt="twitter icon" />
                <img className="w-4" src={google} alt="google icon" />
              </div> */}
            </div>
            <div className="w-0 md:w-1/12"></div>
            <Limoform />
          </div>
          <div className="w-full px-4 sm:px-8">
            <Getquote />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SeatleContent;
