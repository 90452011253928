import React from "react";
import Getquote from "../Getquote";

function AirportContent() {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full p-4 bg-yellow-500 sm:px-40">
        <h1 className="text-lg font-semibold text-white sm:text-2xl">
          Sea-Tac Airport Information
        </h1>
      </div>
      <div className="flex flex-col items-center w-full pt-4 bg-white">
        <div className="flex flex-col items-center w-full lg:w-10/12 sm:p-8 ">
          <div className="flex w-full sm:px-16 sm:flex-row flex-col px-4 sm:space-x-4 h-[50vh]">
            <div className="flex flex-col items-center w-full space-y-4 ">
              <p>
                Sea-Tac International Airport is the main airport for the
                Seattle metro area. Below, we’ve added useful information to
                check when flying in our out of Seattle.
              </p>
              <div className="flex justify-start w-full space-x-4">
                <div className="text-gray-700 text-md sm:text-lg">
                  <a href="https://www.portseattle.org/sea-tac" target="_blank">Sea-Tac International Airport Official Website</a>
                </div>
                <div className="text-gray-700 text-md sm:text-lg">
                  <a href="https://www.portseattle.org/sea-tac/maps" target='_blank'>Sea-Tac International Airport Map</a>
                </div>
                <div className="text-gray-700 text-md sm:text-lg">
                  <a href="https://www.tsa.gov/" target='_blank'>TSA Information</a>
                </div>
              </div>
              {/* <div className="flex items-center justify-center w-full space-x-2">
                <p>Share</p>
                <img className="w-4" src={facebook} alt="facebook icon" />
                <img className="w-4" src={twitter} alt="twitter icon" />
                <img className="w-4" src={google} alt="google icon" />
              </div> */}
            </div>
          </div>
          <div className="w-full px-4 sm:px-16">
            <Getquote />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AirportContent;
