import React from 'react'
import Footer from '../../../components/Footer'
import Navbar from '../../../components/Navbar'
import BellevueSedanContent from '../../../components/ServiceComponents/BellevueSedanContent'

function BellevueSedanPage() {
  return (
    <>
    <Navbar/>
    <BellevueSedanContent/>
    <Footer/>
    </>
  )
}

export default BellevueSedanPage