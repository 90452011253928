import React from 'react'
import Footer from '../components/Footer'
import Carousel from '../components/HomeComponents/Carousel'
import Homecontent from '../components/HomeComponents/Homecontent'
import Navbar from '../components/Navbar'

function Homepage() {
  return (
    <div>
        <Navbar/>
        <Carousel/>
        <Homecontent/>
        <Footer/>
    </div>
  )
}

export default Homepage