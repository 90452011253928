import React from 'react'
import Footer from '../../../components/Footer'
import Navbar from '../../../components/Navbar'
import SchoolContent from '../../../components/ServiceComponents/SchoolContent'

function SchoolPage() {
  return (
    <>
    <Navbar/>
    <SchoolContent/>
    <Footer/>
    </>
  )
}

export default SchoolPage