import React from "react";
import Getquote from "../Getquote";
import Limoform from "../Limoform";
import img1 from "../../assets/limo-corporate-accounts1.jpg";

function CorporateContent() {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full px-16 py-4 bg-yellow-500 sm:px-40">
        <h1 className="text-lg font-semibold text-white sm:text-2xl">
          Limo Corporate Accounts
        </h1>
      </div>
      <div className="flex flex-col items-center w-full bg-white">
        <div className="flex flex-col items-center w-full sm:w-10/12 sm:p-8 ">
          <div className="flex flex-col w-full px-4 sm:space-x-4 sm:flex-row lg:px-16">
            <div className="flex flex-col items-center w-full space-y-4 sm:w-8/12 ">
              <img src={img1} alt="" />
              {/* <h1 className='text-3xl font-light text-center text-yellow-700'>MS Limousine Fleet</h1> */}
              <p className="text-lg text-gray-700">
              MS Limo offers corporate accounts for our clients. Please
                contact us for more information regarding your specific account
                needs.
              </p>
              {/* <div className="flex items-center justify-center w-full space-x-2">
                <p>Share</p>
                <img className="w-4" src={facebook} alt="facebook icon" />
                <img className="w-4" src={twitter} alt="twitter icon" />
                <img className="w-4" src={google} alt="google icon" />
              </div> */}
            </div>
            <div className="w-0 sm:w-1/12"></div>
            <Limoform />
          </div>
          <div className="w-full px-4 sm:px-8">
            <Getquote />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CorporateContent;
