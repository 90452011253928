import React from "react";
import img1 from "../../assets/seattle-luxury-sedan-service.jpg";
import Getquote from "../Getquote";
import Limoform from "../Limoform";

function SedanContent() {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full p-4 bg-yellow-500 sm:px-40">
        <h1 className="text-lg font-semibold text-white sm:text-2xl">
          Seattle Luxury Sedan Service
        </h1>
      </div>
      <div className="flex flex-col items-center w-full pt-4 bg-white">
        <div className="flex flex-col items-center w-full lg:w-10/12 sm:p-8 ">
          <div className="flex flex-col w-full px-4 sm:flex-row sm:px-16 sm:space-x-4">
            <div className="flex flex-col items-center w-full space-y-4 sm:w-8/12 ">
              <img src={img1} alt="" />
              <h1 className="text-xl font-light text-center text-yellow-700 sm:text-3xl">
              MS Limousine's Seattle Luxury Sedan Service
              </h1>
              <p className="text-gray-700 text-md sm:text-lg">
              MS Limousine is available for your limousine and Luxury Sedan
                transportation needs. Whether you need service to and from
                Sea-Tac International Airport or are spending a night out on the
                town, let MS Limousine provide you with a Luxury Sedan and a
                professional chauffeur.
              </p>

              <p className="text-lg text-gray-700">
                Experience the reasons why our clients use our Luxury Sedan
                services exclusively.Call MS Limousine today!
              </p>
              {/* <div className="flex items-center justify-center w-full space-x-2">
                <p>Share</p>
                <img className="w-4" src={facebook} alt="facebook icon" />
                <img className="w-4" src={twitter} alt="twitter icon" />
                <img className="w-4" src={google} alt="google icon" />
              </div> */}
            </div>
            <div className="w-0 md:w-1/12"></div>
            <Limoform />
          </div>
          <div className="w-full px-4 sm:px-8">
            <Getquote />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SedanContent;
