import React from 'react'
import Footer from '../../../components/Footer'
import Navbar from '../../../components/Navbar'
import SeatleContent from '../../../components/ServiceComponents/SeatleContent'

function SeattlePage() {
  return (
    <>
        <Navbar/>
        <SeatleContent/>
        <Footer/>

    </>
  )
}

export default SeattlePage